import store from 'store'
import { getCurrentAccountSelector } from 'selectors'
import { emailValidator } from 'add_edit_modals/FormField/validators'
import { EMAIL_VALIDATION_MODE_SOFT, EMAIL_VALIDATION_MODE_HARD } from './constants'

export const shouldPerformEmailVerification = (email) => {
  const { valid } = emailValidator({ fieldValue: email })

  return email &&
    valid &&
    window.Features.email_validation
}

export const isEmailValidationModeSoft = () => {
  const currentAccount = getCurrentAccountSelector(store.getState())
  const emailValidationModeSetting = currentAccount.email_validation_mode

  return emailValidationModeSetting === EMAIL_VALIDATION_MODE_SOFT
}

export const isEmailValidationModeHard = () => {
  const currentAccount = getCurrentAccountSelector(store.getState())
  const emailValidationModeSetting = currentAccount.email_validation_mode

  return emailValidationModeSetting === EMAIL_VALIDATION_MODE_HARD
}

export const notifyHardModeQuotaExceeded = () => {
  window.Helpers.notify(
    'error',
    'You have exceeded your email validation quota. This record will be saved without email validation.'
  )
}
