import { createSelector } from 'reselect'
import * as dealWonReasonsConstants from 'admin/deal_won_reasons/constants'
import * as dealLossReasonsConstants from './admin/deal_loss_reasons/constants'
import * as dealStatusConstants from './admin/deal_statuses/constants'
import sortByPosition from 'modules/sortByPosition'
import {
  PERSON_STATUS_CREATE,
  PERSON_STATUS_UPDATE,
  PERSON_STATUS_DELETE
} from 'admin/person_statuses/constants'

import {
  TAG_CREATE,
  TAG_UPDATE,
  TAG_DELETE
} from 'admin/tags/constants'

import {
  DOCUMENT_TAG_CREATE,
  DOCUMENT_TAG_UPDATE,
  DOCUMENT_TAG_DELETE
} from 'admin/document_tags/constants'

import {
  SOURCE_CREATE,
  SOURCE_UPDATE,
  SOURCE_DELETE
} from 'admin/sources/constants'

import {
  ACTIVITY_CATEGORY_CREATE,
  ACTIVITY_CATEGORY_UPDATE,
  ACTIVITY_CATEGORY_DELETE
} from 'admin/activity_categories/constants'

import { UPDATE_IS_API_ENABLED, UPDATE_FORMS } from 'constants/Account'
import {
  ACCOUNT_EXPORT_CREATE,
  ACCOUNT_EXPORT_UPDATE,
  ACCOUNT_EXPORT_DELETE
} from 'admin/data_exports/constants'

import {
  EVENT_TYPE_CREATE,
  EVENT_TYPE_UPDATE,
  EVENT_TYPE_DELETE
} from 'admin/event_types/constants'

import {
  TODO_TEMPLATE_CREATE,
  TODO_TEMPLATE_UPDATE,
  TODO_TEMPLATE_DELETE,
  TODO_TEMPLATE_REMOVE_TASK
} from 'admin/todo_templates/constants'

import {
  OTHER_EMAIL_CREATE,
  OTHER_EMAIL_UPDATE,
  OTHER_EMAIL_DELETE
} from 'MyProfile/components/email/UserOtherEmails/constants'

import {
  SET_CURRENT_ITEM
} from './constants'

import {
  CALENDAR_ENTRY_PRIORITY_CREATE,
  CALENDAR_ENTRY_PRIORITY_UPDATE,
  CALENDAR_ENTRY_PRIORITY_DELETE
} from 'admin/calendar_entry_priorities/constants'

import {
  CALENDAR_ENTRY_POSTPONE_PERIOD_CREATE,
  CALENDAR_ENTRY_POSTPONE_PERIOD_DELETE,
  CALENDAR_ENTRY_POSTPONE_PERIOD_WEEKENDS,
  CALENDAR_ENTRY_USE_CALENDAR_TO_POSTPONE
} from 'admin/calendar_entry_postpone_periods/constants'
import * as constants from './admin/product_lines/constants'

// CONSTANTS
export const LOAD_APP = 'LOAD_APP'
export const PRELOAD_APP = 'PRELOAD_APP'
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'
export const UPDATE_ACCOUNT_CURRENCY = 'UPDATE_ACCOUNT_CURRENCY'
export const UPDATE_ACCOUNT_SELECTED_CURRENCIES = 'UPDATE_ACCOUNT_SELECTED_CURRENCIES'
export const UPDATE_ACCOUNT_USERS = 'UPDATE_ACCOUNT_USERS'
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'

// ACTIONS
export const loadApp = payload => ({ payload, type: LOAD_APP })
export const preloadApp = () => ({ type: PRELOAD_APP })
export const updateAccount = payload => ({ payload, type: UPDATE_ACCOUNT })
export const updateAccountUsers = payload => ({ payload, type: UPDATE_ACCOUNT_USERS })
export const updateAccountCurrency = payload =>
  ({ payload, type: UPDATE_ACCOUNT_CURRENCY })
export const updateAccountSelectedCurrencies = payload =>
  ({ payload, type: UPDATE_ACCOUNT_SELECTED_CURRENCIES })
export const updateCurrentUser = payload => ({ payload, type: UPDATE_CURRENT_USER })

// SELECTORS
export const getAccount = state =>
  state.modern.currentAccount
export const getAccountCurrency = state =>
  state.modern.currentAccount.currency
export const getSelectedCurrencies = state =>
  state.modern.currentAccount.selected_currencies
export const getSortedSelectedCurrencies = createSelector(
  getSelectedCurrencies,
  currencies => currencies.sort(sortByPosition)
)
export const getAccountDealStages = state =>
  state.modern.currentAccount.deal_stages
export const getUserCurrency = state =>
  state.modern.currentUser.currency

export const getAccountTeams = state =>
  state.modern.currentAccount.teams

export const getAccountUsers = state =>
  state.modern.currentAccount.users

export const getAccountProductLines = state =>
  state.modern.currentAccount.product_lines

// FEATURES

export const getUserFeatures = state =>
  window.Features
export const getHasMultiCurrency = createSelector(
  getUserFeatures,
  features => features.multi_currency
)
export const getHasDealIntelligence = createSelector(
  getUserFeatures,
  features => features.deal_intelligence
)
export const getHasCustomerManagement = createSelector(
  getUserFeatures,
  features => features.customer_management
)

export const getHasProductLines = createSelector(
  getUserFeatures,
  features => features.product_lines
)

// INITIAL STATE
export const initialState = {
  currentUser: undefined,
  currentAccount: undefined,
  loaded: false
}

// REDUCER
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_APP:
      return {
        ...state,
        ...action.payload,
        loaded: true
      }
    case SET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: action.payload
      }
    case UPDATE_ACCOUNT:
      return {
        ...state,
        currentAccount: action.payload
      }
    case UPDATE_ACCOUNT_CURRENCY:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          currency: action.payload
        }
      }
    case UPDATE_ACCOUNT_SELECTED_CURRENCIES:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          selected_currencies: action.payload
        }
      }
    case UPDATE_ACCOUNT_USERS:
      return { ...state, currentAccount: { ...state.currentAccount, users: [...state.currentAccount.users, action.payload] } }
    case dealWonReasonsConstants.DEAL_WON_REASON_CREATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          deal_won_reasons: [...state.currentAccount.deal_won_reasons, action.payload]
        }
      }
    case UPDATE_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload
      }
    case dealWonReasonsConstants.DEAL_WON_REASON_UPDATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          deal_won_reasons: state.currentAccount.deal_won_reasons.map(item => item.id !== action.payload.id ? item : action.payload)
        }
      }
    case dealWonReasonsConstants.DEAL_WON_REASON_DELETE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          deal_won_reasons: state.currentAccount.deal_won_reasons.filter(item => item.id !== action.payload.id)
        }
      }
    case dealWonReasonsConstants.DEAL_WON_REASON_REQUIRED_CHANGE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          deal_won_reason_required: action.payload.deal_won_reason_required
        }
      }
    case dealWonReasonsConstants.DEAL_WON_REASON_SHOW_DIALOG_CHANGE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          show_deal_won_reason_dialog: action.payload.show_deal_won_reason_dialog
        }
      }
    case dealWonReasonsConstants.DEAL_WON_REASON_SHOW_DEAL_PROFILE_CHANGE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          show_deal_won_reason_deal_profile: action.payload.show_deal_won_reason_deal_profile
        }
      }
    case dealLossReasonsConstants.DEAL_LOSS_REASON_CREATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          deal_loss_reasons: [...state.currentAccount.deal_loss_reasons, action.payload]
        }
      }
    case dealLossReasonsConstants.DEAL_LOSS_REASON_UPDATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          deal_loss_reasons: state.currentAccount.deal_loss_reasons.map(item => item.id !== action.payload.id ? item : action.payload)
        }
      }
    case dealLossReasonsConstants.DEAL_LOSS_REASON_DELETE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          deal_loss_reasons: state.currentAccount.deal_loss_reasons.filter(item => item.id !== action.payload.id)
        }
      }
    case dealLossReasonsConstants.DEAL_LOSS_REASON_REQUIRED_CHANGE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          deal_loss_reason_required: action.payload.deal_loss_reason_required
        }
      }
    case dealLossReasonsConstants.DEAL_LOSS_REASON_SHOW_DIALOG_CHANGE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          show_deal_loss_reason_dialog: action.payload.show_deal_loss_reason_dialog
        }
      }
    case dealLossReasonsConstants.DEAL_LOSS_REASON_SHOW_DEAL_PROFILE_CHANGE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          show_deal_loss_reason_deal_profile: action.payload.show_deal_loss_reason_deal_profile
        }
      }
    case dealStatusConstants.DEAL_STATUS_CREATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          deal_statuses: [...state.currentAccount.deal_statuses, action.payload]
        }
      }
    case dealStatusConstants.DEAL_STATUS_UPDATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          deal_statuses: state.currentAccount.deal_statuses.map(item => item.id !== action.payload.id ? item : action.payload)
        }
      }
    case dealStatusConstants.DEAL_STATUS_DELETE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          deal_statuses: state.currentAccount.deal_statuses.filter(item => item.id !== action.payload.id)
        }
      }
    case PERSON_STATUS_CREATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          lead_statuses: [...state.currentAccount.lead_statuses, action.payload]
        }
      }
    case PERSON_STATUS_UPDATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          lead_statuses: state.currentAccount.lead_statuses.map(item => item.id !== action.payload.id ? item : action.payload)
        }
      }
    case PERSON_STATUS_DELETE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          lead_statuses: state.currentAccount.lead_statuses.filter(item => item.id !== action.payload.id)
        }
      }
    case TAG_CREATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          [`${action.payload.type === 'person' ? 'predefined_contacts' : action.payload.type}_tags`]:
            [...state.currentAccount[`${action.payload.type === 'person' ? 'predefined_contacts' : action.payload.type}_tags`], action.payload].sort((p, n) => p.name.localeCompare(n.name))
        }
      }
    case TAG_UPDATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          [`${action.payload.type === 'person' ? 'predefined_contacts' : action.payload.type}_tags`]:
            state.currentAccount[`${action.payload.type === 'person' ? 'predefined_contacts' : action.payload.type}_tags`].map(item => item.id !== action.payload.id ? item : action.payload).sort((p, n) => p.name.localeCompare(n.name))
        }
      }
    case TAG_DELETE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          [`${action.payload.type === 'person' ? 'predefined_contacts' : action.payload.type}_tags`]:
            state.currentAccount[`${action.payload.type === 'person' ? 'predefined_contacts' : action.payload.type}_tags`].filter(item => item.id !== action.payload.id)
        }
      }
    case DOCUMENT_TAG_CREATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          document_tags: [...state.currentAccount.document_tags, action.payload]
        }
      }
    case DOCUMENT_TAG_UPDATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          document_tags: state.currentAccount.document_tags.map(item => item.id !== action.payload.id ? item : action.payload)
        }
      }
    case DOCUMENT_TAG_DELETE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          document_tags: state.currentAccount.document_tags.filter(item => item.id !== action.payload.id)
        }
      }
    case SOURCE_CREATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          lead_sources: [...state.currentAccount.lead_sources, action.payload]
        }
      }
    case SOURCE_UPDATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          lead_sources: state.currentAccount.lead_sources.map(item => item.id !== action.payload.id ? item : action.payload)
        }
      }
    case SOURCE_DELETE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          lead_sources: state.currentAccount.lead_sources.filter(item => item.id !== action.payload.id)
        }
      }
    case ACTIVITY_CATEGORY_CREATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          note_categories: [...state.currentAccount.note_categories, action.payload]
        }
      }
    case ACTIVITY_CATEGORY_UPDATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          note_categories: state.currentAccount.note_categories.map(item => item.id !== action.payload.id ? item : action.payload)
        }
      }
    case ACTIVITY_CATEGORY_DELETE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          note_categories: state.currentAccount.note_categories.filter(item => item.id !== action.payload.id)
        }
      }
    case ACCOUNT_EXPORT_CREATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          account_exports: [...state.currentAccount.account_exports, action.payload]
        }
      }
    case ACCOUNT_EXPORT_UPDATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          account_exports: state.currentAccount.account_exports.map(item => item.id !== action.payload.id ? item : action.payload)
        }
      }
    case ACCOUNT_EXPORT_DELETE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          account_exports: state.currentAccount.account_exports.filter(item => item.id !== action.payload.id)
        }
      }
    case EVENT_TYPE_CREATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          event_categories: [...state.currentAccount.event_categories, action.payload]
        }
      }
    case EVENT_TYPE_UPDATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          event_categories: state.currentAccount.event_categories.map(item => item.id !== action.payload.id ? item : action.payload)
        }
      }
    case EVENT_TYPE_DELETE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          event_categories: state.currentAccount.event_categories.filter(item => item.id !== action.payload.id)
        }
      }
    case TODO_TEMPLATE_CREATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          todo_templates: [...state.currentAccount.todo_templates, action.payload]
        }
      }
    case TODO_TEMPLATE_UPDATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          todo_templates: state.currentAccount.todo_templates.map(item => item.id !== action.payload.id ? item : action.payload)
        }
      }
    case TODO_TEMPLATE_DELETE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          todo_templates: state.currentAccount.todo_templates.filter(item => item.id !== action.payload.id)
        }
      }
    case TODO_TEMPLATE_REMOVE_TASK:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          todo_templates: state.currentAccount.todo_templates.map(template => {
            if (template.id === action.payload.todoTemplate.id) {
              template.todo_template_items = template.todo_template_items.filter(item => item.id !== action.payload.taskId)
            }
            return template
          })
        }
      }
    case OTHER_EMAIL_CREATE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          other_emails: action.payload.other_emails
        }
      }
    case OTHER_EMAIL_UPDATE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          other_emails: action.payload.other_emails
        }
      }
    case OTHER_EMAIL_DELETE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          other_emails: state.currentUser.other_emails.filter(item => item.id !== action.payload.id)
        }
      }
    case CALENDAR_ENTRY_PRIORITY_CREATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          calendar_entry_priorities: [...state.currentAccount.calendar_entry_priorities, action.payload]
        }
      }
    case CALENDAR_ENTRY_PRIORITY_UPDATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          calendar_entry_priorities: state.currentAccount.calendar_entry_priorities.map(item => item.id !== action.payload.id ? item : action.payload)
        }
      }
    case CALENDAR_ENTRY_PRIORITY_DELETE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          calendar_entry_priorities: state.currentAccount.calendar_entry_priorities.filter(item => item.id !== action.payload.id)
        }
      }
    case CALENDAR_ENTRY_POSTPONE_PERIOD_CREATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          calendar_entry_postpone_periods: action.payload
        }
      }
    case CALENDAR_ENTRY_POSTPONE_PERIOD_DELETE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          calendar_entry_postpone_periods: action.payload
        }
      }
    case CALENDAR_ENTRY_POSTPONE_PERIOD_WEEKENDS:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          calendar_entry_postpone_weekends: action.payload
        }
      }
    case CALENDAR_ENTRY_USE_CALENDAR_TO_POSTPONE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          calendar_entry_use_calendar_to_postpone: action.payload
        }
      }
    case constants.PRODUCT_LINE_CREATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          product_lines: [...state.currentAccount.product_lines, action.payload]
        }
      }
    case constants.PRODUCT_LINE_UPDATE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          product_lines: state.currentAccount.product_lines.map(line => line.id !== action.payload.id ? line : action.payload)
        }
      }
    case constants.PRODUCT_LINE_DELETE:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          product_lines: state.currentAccount.product_lines.filter(l => l.id !== action.payload.id)
        }
      }
    case UPDATE_IS_API_ENABLED:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          is_api_enabled: action.payload
        }
      }
    case UPDATE_FORMS:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          forms: action.payload
        }
      }
    default:
      return state
  }
}
