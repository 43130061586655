import store from 'store'
import { useContext } from 'react'
import camelize from 'camelize'
import { filterActiveUsers } from 'modules/userFiltering'

import { getCurrentUserSelector } from 'selectors'
import {
  getUsers,
  getHigherUpUsers,
  getReadOnlyUsers,
  getVisibleUsers,
  getVisibleActiveUsersForCompanyOwner,
  getVisibleActiveUsersForDealOwner,
  getVisibleActiveUsersForPersonOwner,
  rewindUserV3ToOrig
} from 'selectors/Account'
import { getVisibleActiveUsers } from 'VisibleUsers/selectors'
import { getSelectedCurrencies } from 'ducks'

import { FormContext } from 'add_edit_modals/context'

export const resourceTypes = ['company', 'deal', 'person', 'calendar_task', 'calendar_event', 'quick_add']
export const customizableTypes = ['company', 'deal', 'person']

export const isDefaultOpen = (id) => {
  const { resourceType } = useContext(FormContext)

  if (!window.User.hasOwnProperty(resourceType + 'JsGroupState')) return false
  return window.User[resourceType + 'JsGroupState'][id] === 'opened'
}

export const capitalize = (word) => word[0].toUpperCase() + word.substr(1)
export const classify = (word) => capitalize(camelize(word))

const addCurrentOwner = (possibleOwners, currentOwnerId) => {
  const visibleUsers = getVisibleUsers(store.getState())
  const currentOwner = visibleUsers.find(u => u.id === currentOwnerId)
  if (currentOwner && !possibleOwners.find(o => o.id === currentOwner.id)) {
    possibleOwners = possibleOwners.concat(currentOwner)
  }

  return possibleOwners
}

const mapOwners = (possibleOwners) => {
  return possibleOwners.map(u => ({ label: u.name, value: u.id }))
}

export const possibleOwnersFor = (resourceType, fieldValue) => {
  const visibleActiveUsersForCompanyOwner = getVisibleActiveUsersForCompanyOwner(store.getState())
  const visibleActiveUsersForDealOwner = getVisibleActiveUsersForDealOwner(store.getState())
  const visibleActiveUsersForPersonOwner = getVisibleActiveUsersForPersonOwner(store.getState())
  const visibleActiveUsers = getVisibleActiveUsers(store.getState())

  let possibleOwners = {
    company: visibleActiveUsersForCompanyOwner,
    deal: visibleActiveUsersForDealOwner,
    person: visibleActiveUsersForPersonOwner
  }[resourceType]

  if (!possibleOwners) {
    possibleOwners = visibleActiveUsers
  }

  return mapOwners(addCurrentOwner(possibleOwners, fieldValue))
}

export const visibleUsersForEntity = (entity) => {
  if (entity) {
    // TODO: remove the line below once we get rid of the Backbone code
    //  (not sure if somewhere in the code we are still passing the Backbone models)
    if (entity.attributes) { entity = entity.attributes }

    const accountUsers = getUsers(store.getState())
    let users = accountUsers.filter(user => entity.possible_notify_user_ids.includes(user.id))

    if (entity.user_id !== undefined) {
      const entityOwner = accountUsers.find(user => user.id === entity.user_id)
      if (entityOwner) {
        users = [...users, entityOwner]
      }
    }

    const sharedUsers = users.filter(user => entity.shared_user_ids.includes(user.id))
    users = [...users, ...sharedUsers]

    const activeHigherUpUsers = filterActiveUsers(getHigherUpUsers(store.getState()))
    users = [...users, ...activeHigherUpUsers]

    const currentUserId = getCurrentUserSelector(store.getState()).id
    const currentUser = accountUsers.find(user => user.id === currentUserId)
    users = [currentUser, ...users]

    const readOnlyUserIds = getReadOnlyUsers(store.getState()).map(u => u.id)
    users = users.filter(user => !readOnlyUserIds.includes(user.id))

    users = users.filter((user, index, self) => index === self.findIndex(u => u.id === user.id))

    return users.map(user => rewindUserV3ToOrig(user))
  } else {
    return getVisibleActiveUsers(store.getState())
  }
}

export const possibleTaskEventOwners = (entity, fieldValue) => {
  const possibleOwners = visibleUsersForEntity(entity)
  return mapOwners(addCurrentOwner(possibleOwners, fieldValue))
}

export const possibleCurrencies = (selectedCurrencyId) => {
  const selectedCurrencies = getSelectedCurrencies(store.getState())
  let possibleCurrencies = [...selectedCurrencies]
  possibleCurrencies = possibleCurrencies.sort((a, b) => a.position - b.position).map(c => ({ label: `${c.code} ${c.symbol} - ${c.name}`, value: c.id }))

  if (selectedCurrencyId && !possibleCurrencies.map(c => c.value).includes(selectedCurrencyId)) {
    possibleCurrencies.unshift({ label: selectedCurrencyId, value: selectedCurrencyId })
  }

  return possibleCurrencies
}

export const toBool = (val) => [true, 'true', 1, '1'].includes(val)

export const resourceToUrlMap = {
  person: '/people',
  deal: '/deals',
  company: '/companies',
  customer: '/companies',
  calendar_event: '/calendar_events',
  calendar_task: '/calendar_tasks',
  quick_add: '/quick_add'
}

export const humanizeResourceType = (resourceType) => {
  switch (resourceType) {
    case 'calendar_task':
      return 'Calendar Task'
    case 'calendar_event':
      return 'Calendar Event'
    case 'quick_add':
      return 'Quick Add'
    default:
      return capitalize(resourceType)
  }
}

export const valuesToReject = (resourceType) => {
  switch (resourceType) {
    case 'deal':
      return [
        'is_allowed_to_delete',
        'can_be_reassigned_by_current_user',
        'sharees',
        'stage_name',
        'company_path',
        'next_entry',
        'primary_contact',
        'possible_notify_user_ids',
        'documents',
        'notes',
        'people',
        'commission',
        'expected_close_date_dummy',
        'latest_activity',
        'profile_layout',
        'source',
        'deal_pipeline',
        'days_active',
        'time_to_close',
        'deal_stage',
        'deal_status',
        'import',
        'user',
        'company',
        'owner_name',
        'status_color',
        'status_name',
        'updated_at',
        'days_in_stage',
        'date_of_first_activity',
        'hours_to_first_activity',
        'days_since_last_activity',
        'latest_deal_stage_change',
        'customer_days_in_health',
        'health_score_changed_at',
        'currency',
        'revenue_type',
        'owner_deleted_at',
        'can_be_merged_by_current_user',
        'deal_weighted_forecast',
        'deal_time_to_dollar',
        'deal_days_active',
        'deal_time_to_close',
        'product_lines',
        'created_by_user_id',
        'updated_by_user_id',
        'deal_time_to_dollar_change',
        'number_of_days_in_stages',
        'activities_created_count',
        'days_without_activity',
        'tags'
      ]
    case 'person':
      return [
        'is_allowed_to_delete',
        'can_be_reassigned_by_current_user',
        'full_name',
        'company_path',
        'company_address',
        'home_address',
        'latest_activity',
        'lead_status',
        'profile_layout',
        'source',
        'work_address',
        'possible_notify_user_ids',
        'notes',
        'deals',
        'documents',
        'tags',
        'work_address_google_maps_url',
        'cached_tag_ids',
        'owner_name',
        'updated_at',
        'has_deals',
        'date_of_first_activity',
        'hours_to_first_activity',
        'days_since_last_activity',
        'userCurrencySymbol',
        'owner_deleted_at',
        'created_by_user_id'
      ]
    case 'company':
      return [
        'can_be_deleted_by_current_user',
        'can_be_merged_by_current_user',
        'address',
        'pdf_company_path',
        'possible_notify_user_ids',
        'notes',
        'deals',
        'people',
        'documents',
        'total_pipeline',
        'won_value',
        'worked_on_by',
        'owner_name',
        'loggedUserIsExecutive',
        'updated_at',
        'people_for_notes_association',
        'has_deals',
        'has_people',
        'customer',
        'userCurrencySymbol',
        'owner_deleted_at',
        'tags',
        'hierarchy_details'
      ]
    case 'calendar_event':
      return [
        'start_time',
        'end_time',
        'primary_contact',
        'contact',
        '_date_select_for_start_time_date',
        '_date_select_for_end_time_date',
        '_date_select_for_recurrence_end',
        '_date_select_for_due_date',
        'primary_contact_id',
        'company_name',
        'company',
        'possible_notify_user_ids',
        'shared_user_ids',
        'deal_id',
        'person_id',
        'contact_id',
        'lead_id',
        'deal_name',
        'person_name',
        'contact_name',
        'lead_name',
        'deal',
        'lead',
        'updated_at',
        'created_by_user_id',
        'repeat_this_entry'
      ]
    case 'calendar_task':
      return [
        'start_time',
        'end_time',
        'primary_contact',
        '_date_select_for_start_time_date',
        '_date_select_for_end_time_date',
        '_date_select_for_recurrence_end',
        '_date_select_for_due_date',
        'primary_contact_id',
        'company_name',
        'company',
        'created_at',
        'contact',
        'updated_at',
        'possible_notify_user_ids',
        'shared_user_ids',
        'deal_id',
        'person_id',
        'contact_id',
        'lead_id',
        'deal_name',
        'person_name',
        'contact_name',
        'lead_name',
        'deal',
        'lead',
        'repeat_this_entry'
      ]
    default:
      return []
  }
}

export const fieldIdMapped = (fieldId) => {
  if (fieldId === 'is_lead') return 'type'
  if (fieldId === 'deal_revenue_type') return 'revenue_type_id'
  if (fieldId === 'deal_primary_contact_id') return 'primary_contact_id'
  if (fieldId === 'company_id') return 'company_name'
  return fieldId
}

export const inverseFieldIdMapped = (fieldId) => {
  if (fieldId === 'type') return 'is_lead'
  if (fieldId === 'revenue_type_id') return 'deal_revenue_type'
  if (fieldId === 'primary_contact_id') return 'deal_primary_contact_id'
  if (fieldId === 'company_name') return 'company_id'
  return fieldId
}

export const addMandatoryFields = (resourceType, changedAttrs, currentValues) => {
  if (resourceType === 'calendar_event' || resourceType === 'calendar_task') {
    if (changedAttrs.recurrence_end || changedAttrs.recurrence_frequency) {
      changedAttrs.recurrence_frequency ||= currentValues[resourceType].recurrence_frequency
      changedAttrs.recurrence_end ||= currentValues[resourceType].recurrence_end
    }
  }

  return changedAttrs
}

export const withQuickAddForm = (forms) => {
  forms.quick_add = [
    {
      id: 'company',
      label: 'Company',
      parent_id: null,
      opened: true,
      system: true,
      display_as_field: false,
      group: true,
      hide_group_header: false,
      children: forms.company_quick_add
    },
    {
      id: 'deal',
      label: 'Deal',
      parent_id: null,
      opened: true,
      system: true,
      display_as_field: false,
      group: true,
      hide_group_header: false,
      children: forms.deal_quick_add
    },
    {
      id: 'person',
      label: 'Person',
      parent_id: null,
      opened: true,
      system: true,
      display_as_field: false,
      group: true,
      hide_group_header: false,
      children: forms.person_quick_add
    }
  ]
  return forms
}
