import fetcher from 'modules/fetcher'
import moment from 'moment'
import { toBool } from '../helpers'
import { shouldPerformEmailVerification, isEmailValidationModeSoft } from 'EmailValidation/utils'
import { callEmailVerificationEndpoint, handleEmailVerificationError } from 'EmailValidation/api'

const initialValidationResult = { valid: true, errorMsg: null, meta: {} }

export const hasNoValue = (fieldValue) => fieldValue === undefined || fieldValue === null || fieldValue === '' || (Array.isArray(fieldValue) && !fieldValue.length)

export const presenceValidator = ({ formField, fieldValue, fieldMeta, getFieldValue }) => {
  const { validatePresenceOn, disabled } = fieldMeta
  const value = validatePresenceOn ? getFieldValue(formField.resourceType, validatePresenceOn) : fieldValue

  const valid = disabled || !hasNoValue(value)

  return {
    valid,
    errorMsg: valid ? null : 'Required',
    meta: {}
  }
}

export const emailValidator = ({ fieldValue }) => {
  const valid = hasNoValue(fieldValue) ||
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i.test(String(fieldValue))

  return {
    valid,
    errorMsg: valid ? null : 'Not a valid email address',
    meta: {}
  }
}

export const emailExternalVerificationValidator = async ({ fieldValue }) => {
  if (isEmailValidationModeSoft() || !shouldPerformEmailVerification(fieldValue)) {
    return initialValidationResult
  }

  try {
    const resp = await callEmailVerificationEndpoint(fieldValue)
    return {
      valid: resp.valid,
      errorMsg: resp.error,
      meta: {}
    }
  } catch (error) {
    handleEmailVerificationError(error)
    return initialValidationResult
  }
}

export const currencyValidator = ({ fieldValue }) => {
  const valid = hasNoValue(fieldValue) ||
    /^-?\.?(?:\d+|\d{1,3}(?:[\s.,]\d{3})+)(?:[.,]\d+)?$/.test(String(fieldValue))

  return {
    valid,
    errorMsg: valid ? null : 'Not a valid number',
    meta: {}
  }
}

export const companyNameUniquenessValidator = async ({ fieldValue, resourceId }) => {
  if (hasNoValue(fieldValue)) {
    return initialValidationResult
  }

  const resp = await fetcher('/api/v3/companies.json', {
    query: { view: 'api_v3', name: fieldValue, conditions: { company_name_eq: fieldValue } }
  }).then((resp) => {
    const valid = !resp.entries.length || (resp.entries.length === 1 && resp.entries[0].id === resourceId)

    return {
      valid,
      errorMsg: valid ? null : 'Has to be unique',
      meta: { duplicates: resp.entries }
    }
  })

  return resp
}

export const eventStartTimeValidator = ({ formField, getFieldValue }) => {
  const startTimeDate = getFieldValue(formField.resourceType, 'start_time_date')
  const startTimeTime = getFieldValue(formField.resourceType, 'start_time_time')

  const endTimeDate = getFieldValue(formField.resourceType, 'end_time_date')
  const endTimeTime = getFieldValue(formField.resourceType, 'end_time_time')

  const allDay = toBool(getFieldValue(formField.resourceType, 'all_day'))
  const format = allDay ? 'YYYY/MM/DD' : 'YYYY/MM/DD h:mmA'

  const valid = (!startTimeDate || !endTimeDate) || (moment(`${endTimeDate} ${endTimeTime}`, format) >= moment(`${startTimeDate} ${startTimeTime}`, format))

  return {
    valid,
    errorMsg: valid ? null : 'Should be after start time',
    meta: {}
  }
}
