import { takeEvery } from 'redux-saga' // takeLatest
import { call, put, select } from 'redux-saga/effects' // select
import fetcher from 'modules/fetcher'

import { quotasFetchSuccess } from './actions'
import { QUOTAS_FETCH, QUOTA_VERIFY, QUOTA_TYPE_MAPPING } from './constants'
import { getQuotasInUse } from './selectors'

function * quotasFetchSaga ({ payload: { quotaType } }) {
  const quotasInUse = yield select(getQuotasInUse)

  // Only fetch quotas if account presenter says the quota is active (for performance reasons, account presenter does not contain quota limitations/counts)
  if (quotaType === undefined || quotasInUse.includes(quotaType)) {
    try {
      const response = yield call(fetcher, `/api/internal/quotas/${QUOTA_TYPE_MAPPING[quotaType]}`, {
        method: 'GET'
      })

      yield put(quotasFetchSuccess(response))
    } catch (exception) {
      console.error(exception.message)
    }
  }
}

function * watchQuotasFetchSaga () {
  yield call(takeEvery, [QUOTA_VERIFY, QUOTAS_FETCH], quotasFetchSaga)
}

export const quotasSagas = [watchQuotasFetchSaga()]
